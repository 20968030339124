// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { getStation } from "app/stationMaster/service/Station";
import { useDispatch } from "react-redux";

function MetalInfo({ formData }) {
    const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
   const { station } = useSelector((state) => state.station);

  const { formField, values, errors, touched } = formData;

  const { metalName, metalDescrption } = formField;
   const { setFieldValue, setFieldError } = useFormikContext();
  var { metalName: metalNameV, metalDescrption: metalDescrptionV } = values;


  useEffect(async() => {
      setLoading(true)
      dispatch(getStation());
  

      
      setLoading(false)
    }, [dispatch]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Metal Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
              >
                <InputLabel
                  htmlFor="station"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                 Select Station
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="station"
                  id="station"
                  value={values.station}
                  onChange={(e) => {
                    const stationcode = e.target.value;
                   

                    const selectedStationObj = station?.find(obj => obj.code === stationcode) || {};
                    
                    setFieldValue("stationobj", selectedStationObj );
                    setFieldValue("station", stationcode);
                    //  setFieldValue("hierarchicalcode", selectedDepartmentObj.hierarchicalcode);
                  }}
                >
                   <option value=""></option>
                  {station.map((option, index) => (
                    <option key={option.id} value={option.code}>
                      {option.name} ( {option.code} )
                    </option>
                  ))}
                </Select>
                {errors.station && touched.station && (
                  <FormHelperText error style={{ marginLeft: "0px" , fontWeight:400}}>
                    {errors.station}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={metalName.type}
                label=<span>{metalName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={metalName.name}
                value={metalNameV}
                placeholder={metalName.placeholder}
                error={errors.metalName && touched.metalName}
                success={metalNameV.length > 0 && !errors.metalName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={metalDescrption.type}
                label={metalDescrption.label}
                name={metalDescrption.name}
                value={metalDescrptionV}
                placeholder={metalDescrption.placeholder}
                success={metalDescrptionV.length > 0 && !errors.metalDescrption}
              />
            </Grid>
          </Grid>
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for MetalInfo
MetalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MetalInfo;
