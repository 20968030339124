
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const createRiseTicket = createAsyncThunk("createRiseTicket", async (data, {rejectWithValue}) => {
  try {
    const response = await axios.post(
      `loss-master/api/LossMaster/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRiseTicket = createAsyncThunk("getRiseTicket", async (data, {rejectWithValue}) => {
    try {
      if (data?.losetype && data?.recstatus) {
        const response = await axios.get(
          `loss-master/api/LossMaster/v1/get?
lose_type=${data.losetype}&recstatus=${data.recstatus}`
        );
        return await response.data;
      } else if(data?.losetype){
        const response = await axios.get(
          `loss-master/api/LossMaster/v1/get?
lose_type=${data.losetype}`
        );
        return await response.data;
      }
      else if(data?.recstatus){
        const response = await axios.get(
          `loss-master/api/LossMaster/v1/get?
&recstatus=${data.recstatus}`
        );
        return await response.data;
      }
      else{
        const response = await axios.get(
          `loss-master/api/LossMaster/v1/get` );
        return await response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateRiseTicket = createAsyncThunk("updateRiseTicket", async (data, {rejectWithValue}) => {
  try {
    const response = await axios.put(
      `loss-master/api/LossMaster/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteRiseTicket = createAsyncThunk("deleteRiseTicket", async (data, {rejectWithValue}) => {
  try {
    const response = await axios.put(
      `loss-master/api/LossMaster/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




