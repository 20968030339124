// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import { FormControl, InputLabel, Select, FormHelperText } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getStation } from "app/stationMaster/service/Station";
import { getMetal } from "app/MetalMaster/service/Metal";

function RaiseInfo({ formData }) {
  const dispatch = useDispatch();

  const [statusvalue, setStatusValue] = useState("");
  const [color] = useState("#344767");
  const { station } = useSelector((state) => state.station);
  // const { metal, loading, error } = useSelector((state) => state.metal);
  const [metal, setMetal] = useState([]);
  const [loading, setLoading] = useState(false);

  const { formField, values, errors, touched } = formData;

  const { manPower, roleDescrption } = formField;
  const { setFieldValue, setFieldError } = useFormikContext();
  var { manPower: manPowerV } = values;

  useEffect(async () => {
     if((statusvalue === "MACHINEBREAKDOWN" ||
      statusvalue === "METALNOTAVAILABLE")){
    setLoading(true);
    dispatch(getStation());

    setLoading(false);
     }
  }, [statusvalue]);

  const getMetaldata = async (code) => {
    const data = await dispatch(getMetal(code));

    setMetal(data?.payload?.data.dtoList);
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Raise Ticket</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="losetype"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Lose Type
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="losetype"
                  id="losetype"
                  value={values.losetype}
                  onChange={(e) => {
                    const losetype = e.target.value;
                    setStatusValue(losetype);
                    (values.manPower = ""),
                      (values.station = ""),
                      (values.metal = "");
                    values.stationobj = "";
                    values.metalobj = "";
                    setMetal([]);

                    setFieldValue("losetype", losetype);
                  }}
                >
                  <option value=""></option>
                  <option value="MANPOWER">Man Power</option>
                  <option value="MACHINEBREAKDOWN">Machine Breakdown</option>
                  <option value="METALNOTAVAILABLE">Metal Not Available</option>
                </Select>
                {errors.losetype && touched.losetype && (
                  <FormHelperText
                    error
                    style={{ marginLeft: "0px", fontWeight: 400 }}
                  >
                    {errors.losetype}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {statusvalue === "MANPOWER" && (
              <Grid item xs={12} sm={6}>
                <FormField
                  type={manPower.type}
                  label=<span>
                    {manPower.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  name={manPower.name}
                  value={manPowerV}
                  placeholder={manPower.placeholder}
                  error={errors.manPower && touched.manPower}
                  success={manPowerV.length > 0 && !errors.manPower}
                />
              </Grid>
            )}

            {(statusvalue === "MACHINEBREAKDOWN" ||
              statusvalue === "METALNOTAVAILABLE") && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="station"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Select Station
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="station"
                    id="station"
                    value={values.station}
                    onChange={(e) => {
                      const stationcode = e.target.value;
                      if (statusvalue === "METALNOTAVAILABLE") {
                        getMetaldata(stationcode);
                      }

                      const selectedStationObj =
                        station?.find((obj) => obj.code === stationcode) || {};
console.warn("selectedStationObj......",selectedStationObj)
                      setFieldValue("stationobj", selectedStationObj);
                      setFieldValue("station", stationcode);
                      //  setFieldValue("hierarchicalcode", selectedDepartmentObj.hierarchicalcode);
                    }}
                  >
                    <option value=""></option>

                    {station.map((option, index) => (
                      <option key={option.id} value={option.code}>
                        {option.name} ( {option.code} )
                      </option>
                    ))}
                  </Select>
                  {errors.station && touched.station && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: 400 }}
                    >
                      {errors.station}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}

            {statusvalue === "METALNOTAVAILABLE" && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="metal"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Select Metal
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="metal"
                    id="metal"
                    value={values.metal}
                    onChange={(e) => {
                      const metalcode = e.target.value;
                      const selectedmetalObj =
                        metal?.find((obj) => obj.id == metalcode) || {};

                      setFieldValue("metalobj", selectedmetalObj);
                      setFieldValue("metal", metalcode);
                    }}
                  >
                    <option value=""></option>
                    {metal?.map((option, index) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Select>
                  {errors.metal && touched.metal && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: 400 }}
                    >
                      {errors.metal}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for RaiseInfo
RaiseInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RaiseInfo;
