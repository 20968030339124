
import checkout from "../schemas/form";
const {
  formField: { manPower, station,losetype,metal},
} = checkout;

const initialValues = {
  [manPower.name]: "",
  [station.name]: "",
  [losetype.name]: "",
  [metal.name]: "",
};

export default initialValues;
