import { createSlice } from "@reduxjs/toolkit";
import {
  createMetal,
  deleteMetal,
  getMetal,
  updateMetal,
} from "../service/Metal";

const initialState = {
  metal: [],
  loading: false,
  error: null,
};

export const metalDetail = createSlice({
  name: "metalDetail",
  initialState,
  extraReducers: {
    [createMetal.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createMetal.fulfilled]: (state, action) => {
      state.loading = false;
      state.metal.push(action.payload.data.dto);
    },
    [createMetal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getMetal.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getMetal.fulfilled]: (state, action) => {
      state.loading = false;
      state.metal = action.payload.data.dtoList;
    },
    [getMetal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteMetal.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteMetal.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload);
      const { id } = action.payload.data.dto;
      if (id) {
        state.metal = state.metal.filter((metal) => metal.id !== id);
      }
    },
    [deleteMetal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateMetal.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateMetal.fulfilled]: (state, action) => {
      state.loading = false;

      const index = state.metal.findIndex(
        (metal) => metal.id === action.payload.data.dto.id
      );
      state.metal[index] = action.payload.data.dto;
      //   state.users.push(action.payload);
    },
    [updateMetal.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default metalDetail.reducer;
