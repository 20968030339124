import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MoonLoader } from "react-spinners";
import swal from "sweetalert";
import { getStation } from "app/stationMaster/service/Station";
import { getMetal } from "app/MetalMaster/service/Metal";

const RaiseTicketedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedTicket, openpopup, setopenpopup } = props;
  console.warn('selectedTicket',selectedTicket)
  const { station } = useSelector((state) => state.station);
  const [statusvalue, setStatusValue] = useState(selectedTicket?.lose_type);
 
     const { metal, loading, error } = useSelector((state) => state.metal);

  // Validation schema using Yup
  const validationSchema = Yup.object({
    lose_type: Yup.string().required("Lose Type is required"),
    manpower: Yup.string().required("Man Power is required"),
    station: Yup.string().required("Station selection is required"),
    metal: Yup.string().required("Metal selection is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      lose_type: selectedTicket?.lose_type || "",
      manpower: selectedTicket?.manpower|| "",
      metal: selectedTicket?.metal || "",
    
      station: selectedTicket?.value?.code || selectedTicket?.value?.station_code || "",

    },
    validationSchema,
    onSubmit: async (values) => {
      console.warn(values)
      // const updatedMetal = {
      //   ...selectedTicket,
      //   manpower: values.name,
     
      //   station_code: values.stationObj
      //     ? values.stationObj?.code
      //     : selectedTicket.station_code,
      //   station_name: values.stationObj
      //     ? values.stationObj?.name
      //     : selectedTicket.station_name,
      // };

      // const response = await dispatch(updateMetal(updatedMetal));

      // if (!response.error && !loading) {
      //   setopenpopup(false);
      //   swal("Good job!", "Metal has been successfully updated.", "success");
      // } else {
      //   handleClose();
      //   swal({
      //     icon: "error",
      //     title: "Error",
      //     text: error?.message || "An error occurred while updating the item",
      //   });
      // }
    },
  });

  useEffect(() => {
    dispatch(getStation());
     dispatch(getMetal());
     setStatusValue(selectedTicket?.lose_type)
    // Update the form values when selectedTicket changes
    formik.setValues({
      manpower: selectedTicket?.manpower || "",
      lose_type: selectedTicket?.lose_type || "",
      metal: selectedTicket?.metal || "",
      station: selectedTicket?.value?.code || "",
    });
  }, [selectedTicket]);

  const handleClose = () => {
    formik.setValues({
      lose_type: selectedTicket?.lose_type|| "",
      manpower: selectedTicket?.manpower || "",
      metal: selectedTicket?.metal || "",
      station: selectedTicket?.value?.code || "",
    });
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="lose_type" style={{ marginLeft: "-14px" }}>
                Select Lose Type
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                name="lose_type"
                id="lose_type"
                value={formik.values.lose_type}
                onChange={(e) => {
                  const selectedlose_type = e.target.value;
                  setStatusValue(selectedlose_type);
                  // const selectedStationObj =
                  //   station.find((item) => item.code === selectedStationCode) || {};

                  // Set both the station code and the full object
                  // formik.setFieldValue("station", selectedStationCode);
                  formik.setFieldValue("lose_type", selectedlose_type);
                }}
                error={
                  formik.touched.lose_type && Boolean(formik.errors.lose_type)
                }
              >
                <option value=""></option>

                <option value="MANPOWER">Man Power</option>
                <option value="MACHINEBREAKDOWN">Machine Breakdown</option>
                <option value="METALNOTAVAILABLE">Metal Not Available</option>
              </Select>
              {formik.touched.lose_type && formik.errors.lose_type && (
                <FormHelperText error style={{ fontWeight: 400 }}>
                  {formik.errors.lose_type}
                </FormHelperText>
              )}
            </FormControl>
          </MDBox>
          {statusvalue === "MANPOWER" && (
            <MDBox mb={2} mt={1}>
              <MDInput
                type="input"
                label={
                  <span>
                Man Power
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                }
                variant="standard"
                fullWidth
                id="manpower"
                name="manpower"
                value={formik.values.manpower}
                onChange={formik.handleChange}
                error={formik.touched.manpower && Boolean(formik.errors.manpower)}
                helperText={
                  formik.touched.manpower && formik.errors.manpower ? (
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.manpower}
                    </p>
                  ) : null
                }
              />
            </MDBox>
          )}

          {(statusvalue === "MACHINEBREAKDOWN" ||
            statusvalue === "METALNOTAVAILABLE") && (
            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="station" style={{ marginLeft: "-14px" }}>
                  Select Station
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  name="station"
                  id="station"
                  value={formik.values.station}
                  onChange={(e) => {
                    const selectedStationCode = e.target.value;
                    const selectedStationObj =
                      station.find(
                        (item) => item.code === selectedStationCode
                      ) || {};

                    // Set both the station code and the full object
                    formik.setFieldValue("station", selectedStationCode);
                    formik.setFieldValue("stationObj", selectedStationObj);
                  }}
                  error={
                    formik.touched.station && Boolean(formik.errors.station)
                  }
                >
                  <option value=""></option>
                  {station.map((option, index) => (
                    <option key={option.id} value={option.code}>
                      {option.name} ({option.code})
                    </option>
                  ))}
                </Select>
                {formik.touched.station && formik.errors.station && (
                  <FormHelperText error style={{ fontWeight: 400 }}>
                    {formik.errors.station}
                  </FormHelperText>
                )}
              </FormControl>
            </MDBox>
          )}
          {statusvalue === "METALNOTAVAILABLE" && (
            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="metal" style={{ marginLeft: "-14px" }}>
                  Select Metal
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  name="metal"
                  id="metal"
                  value={formik.values.metal}
                  onChange={(e) => {
                    const selectedmetal = e.target.value;
                    // const selectedStationObj =
                    //   station.find((item) => item.code === selectedStationCode) || {};

                    // Set both the station code and the full object
                    // formik.setFieldValue("station", selectedStationCode);
                    formik.setFieldValue("metal", selectedmetal);
                  }}
                  error={formik.touched.metal && Boolean(formik.errors.metal)}
                >
                  <option value=""></option>
                  {metal?.map((option, index) => (
                    <option key={option.id} value={option.name}>
                      {option.name} 
                    </option>
                  ))}
                </Select>
                {formik.touched.metal && formik.errors.metal && (
                  <FormHelperText error style={{ fontWeight: 400 }}>
                    {formik.errors.metal}
                  </FormHelperText>
                )}
              </FormControl>
            </MDBox>
          )}
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="success" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton size="small" color="info" type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RaiseTicketedit;
