import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { manPower, losetype, station, metal },
} = checkout;

const validations = [
  Yup.object().shape({
    [losetype.name]: Yup.string().required(losetype.errorMsg),

    [manPower.name]: Yup.string().when(losetype.name, {
      is: "MANPOWER",
      then: Yup.string().required(manPower.errorMsg),
      otherwise: Yup.string().optional(),
    }),

    [station.name]: Yup.string().when(losetype.name, {
      is: (value) =>
        value === "MACHINEBREAKDOWN" || value === "METALNOTAVAILABLE",
      then: Yup.string().required(station.errorMsg),
      otherwise: Yup.string().optional(),
    }),

    [metal.name]: Yup.string().when(losetype.name, {
      is: "METALNOTAVAILABLE",
      then: Yup.string().required(metal.errorMsg),
      otherwise: Yup.string().optional(),
    }),
  }),
];

export default validations;
