import ProfileOverview from "layouts/pages/profile/profile-overview";

import SignInBasic from "layouts/authentication/sign-in/basic";

// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";
import NewRegistration from "app/registration/NewRegistration";
import MobileOtpVerification from "app/MobileVerification/MobileOtpverification";
import ForgotPassword from "app/Forgot Password";
import NewMpin from "app/New mpin/NewMpin";

import RoleList from "app/Role Master/componets/RoleList";

import DepartmentList from "app/departmentMaster/components/DepartmentList";

import AttributeList from "app/attributeMaster/componets/AttributeList";

import Ankit from "app/Ankit";

import MachineList from "app/MachineMaster/componets/MachineList";

import DesignationList from "app/designationMaster/components/DesignationList";
import UserList from "app/UserMaster/components/UserList";

import StationList from "app/stationMaster/componets/StationList";
import BOMList from "app/bomMaster/componets/BOMList";
import ItemList from "app/itemMaster/componets/ItemList";

import Analytics from "app/analytics/Analytics";
import Steps from "app/step/Steps";

import ChecklistOperator from "app/ChecklistOperator/ChecklistOperator";
import CheckServiceList from "app/ChecklistOperator/CheckServiceList";
import UploadFile from "app/UploadDocumnet/UploadFile";

import WebSocketUI from "app/webSocketUI/WebSocketUI";

import ConfigurationList from "app/Configuration Master/components/ConfigurationList";

import ProcessList from "app/Process Master/componets/ProcessList";
import PartList from "productlist/PartList";
import ProductList from "productlist/ProductList";
import ShiftList from "app/shiftMaster/componets/ShiftList";
import AdminChecklist from "app/ChecklistOperator/AdminChecklist";
import Pokayokalist from "app/ChecklistOperator/Pokayokalist";
import AdminPokaypkalist from "app/ChecklistOperator/AdminPokaypkalist";
import AssociationMaster from "app/bomMaster/componets/associationMaster";
import LineProduction from "app/lineProduction/LineProduction";
import OEE from "app/oEE/OEE";
import SPC from "app/spc/SPC";
import MetalList from "app/MetalMaster/componets/MetalList";
import RaiseTicket from "app/RaiseTicket/componets/RaiseTicket";

const routes = [
  {
    type: "collapse",

    name: localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Analytics",
        key: "analytics",
        route: "app/Analytics",
        component: <Analytics />,
      },

      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
      {
        name: "New Registration",
        key: "new-registration",
        route: "/app/newregistration/registration",
        component: <NewRegistration />,
      },
      {
        name: "MobileOtp Verification",
        key: "mobile-otp",
        route: "/app/MobileOtpverification/mobile-otp",
        component: <MobileOtpVerification />,
      },
      {
        name: "FORGOT PASSWORD",
        key: "forgot-password",
        route: "/app/ForgotPassword/forgot-password",
        component: <ForgotPassword />,
      },
      {
        name: "NEW MPIN",
        key: "new-mpin",
        route: "/app/NewMpin/new-mpin",
        component: <NewMpin />,
      },
    ],
  },

  {
    type: "collapse",
    name: "Masters",
    key: "masters",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Role Master",
        key: "role-master",
        route: "app/RoleMaster/role-master",
        component: <RoleList />,
      },
      {
        name: "Department Master",
        key: "department-master",
        route: "app/DepartmentMaster/department-master",
        component: <DepartmentList />,
      },
      {
        name: "Designation Master",
        key: "designation-master",
        route: "app/DesignationMaster/designation-master",
        component: <DesignationList />,
      },
      // {
      //   name: "Product Master",
      //   key: "product-master",
      //   route: "app/ProductMaster/product-master",
      //   component: <ProductList/>,
      // },
      {
        name: "Attribute Master",
        key: "attribute-master",
        route: "app/AttributeMaster/attribute-master",
        component: <AttributeList />,
      },
      // {
      //   name: "Model Master",
      //   key: "model-master",
      //   route: "app/ModelMaster/model-master",
      //   component: <ModelList/>,
      // },
      {
        name: "Machine Master",
        key: "machine-master",
        route: "app/MachineMaster/machine-master",
        component: <MachineList />,
      },
      // {
      //   name: "Component Master",
      //   key: "component-master",
      //   route: "app/ComponentMaster/component-master",
      //   component: <ComponentList/>,
      // },
      {
        name: "User Master",
        key: "user-master",
        route: "app/UserMaster/user-master",
        component: <UserList />,
      },
      {
        name: "Station Master",
        key: "station-master",
        route: "app/StationMaster/station-master",
        component: <StationList />,
      },
      {
        name: "BOM Master",
        key: "bom-master",
        route: "app/BOMMaster/bom-master",
        component: <BOMList />,
      },
      {
        name: "Process Master",
        key: "process-master",
        route: "app/ProcessMaster/process-master",
        component: <ProcessList />,
      },
      {
        name: "Item Master",
        key: "item-master",
        route: "app/ItemMaster/item-master",
        component: <ItemList />,
      },
      // {
      //   name: "Configuration Master",
      //   key: "configuration-master",
      //   route: "app/ConfigurationMaster/configuration-master",
      //   component: <ConfigurationList />,
      // },
      {
        name: "Association",
        key: "association",
        route: "/app/association-master/association",
        component: <AssociationMaster />,
      },
      // {
      //   name: "Steps",
      //   key: "steps-ui",
      //   route: "/app/Steps/steps-ui/:id/:name",
      //   component: <Steps/>, 
      // },
    ],
  },

  {
    name: "Ankit List",
    key: "ankit-list",
    route: "/app/Component/ankit-list/:id",
    component: <Ankit />,
  },
  {
    name: "Create Record",
    key: "create-record",
    route: "/app/Component/create-record",
    component: <ChecklistOperator />,
  },
  {
    name: "Record List",
    key: "record-list",
    route: "/app/Component/record-list",
    component: <CheckServiceList />,
  },

  {
    name: "Upload-file",
    key: "upload-file",
    route: "/app/Component/upload-file",
    component: <UploadFile />,
  },

  {
    name: "Part Listing",
    key: "part-list",
    route: "/app/Component/partlist",
    component: <PartList/>,
  },

  {
    name: "Product Detail",
    key: "product-detail",
    route: "/app/Component/product-detail",
    component: <WebSocketUI />,
  },

  {
    name: "Product List",
    key: "product-list",
    route: "/app/Component/Productlist",
    component: <ProductList/>,
  },
  {
    name: "Steps",
    key: "steps-ui",
    route: "/app/Steps/steps-ui/:id/:name",
    component: <Steps/>, 
  },
  {
    name: "Shift List",
    key: "shift",
    route: "/app/Shiftmaster/shift-list",
    component: <ShiftList/>, 
  },
  {
    name: "Check List",
    key: "admin-checklist",
    route: "/app/admin/checklist-list",
    component: <AdminChecklist/>, 
  },
  {
    name: "Metal List",
    key: "metal-list",
    route: "/app/admin/metal-list",
    component: <MetalList/>, 
  },
  {
    name: "Ticket List",
    key: "ticket-list",
    route: "/app/admin/ticket-list",
    component: <RaiseTicket/>, 
  },




  
  {
    name: "Poka yoke",
    key: "poka-yoka",
    route: "/app/admin/poka-yoka",
    component: <Pokayokalist/>, 
  },
  {
    name: "Poka yoke",
    key: "poka-yoke",
    route: "/app/admin/pokayoke-list",
    component: <AdminPokaypkalist/>, 
  },
  {
    name: "Line Production",
    key: "line-production",
    route: "/app/admin/line-production",
    component: <LineProduction/>, 
  },
  {
    name: "Line Production",
    key: "lineproduction",
    route: "/app/line-production",
    component: <LineProduction/>, 
  },
  {
    name: "OEE",
    key: "OEE",
    route: "/app/admin/OEE",
    component: <OEE/>, 
  },
  {
    name: "OEE",
    key: "oee",
    route: "/app/OEE",
    component: <OEE/>, 
  },
  {
    name: "SPC",
    key: "SPC",
    route: "/app/admin/SPC",
    component: <SPC/>, 
  },
];

export default routes;

