
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { MoonLoader } from "react-spinners";
import swal from "sweetalert";
import { getStation } from "app/stationMaster/service/Station";
import { updateMetal } from "../service/Metal";

const Metaledit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedMetal, openpopup, setopenpopup } = props;
const { station } = useSelector((state) => state.station);
  const { error, loading } = useSelector((state) => state.metal);

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Metal Name is required"),
    station: Yup.string().required("Station selection is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedMetal?.name || "",
      description: selectedMetal?.description || "",
      station: selectedMetal?.station_code || "",
    },
    validationSchema,
    onSubmit: async (values) => {
    
      const updatedMetal = {
        ...selectedMetal,
        name: values.name,
        description: values.description,
        station_code:values.stationObj ? values.stationObj?.code :selectedMetal.station_code,
      station_name: values.stationObj ?values.stationObj?.name :selectedMetal.station_name,
      };
    
      const response = await dispatch(updateMetal(updatedMetal));

      if (!response.error && !loading) {
        setopenpopup(false);
        swal("Good job!", "Metal has been successfully updated.", "success");
      } else {
        handleClose();
        swal({
          icon: "error",
          title: "Error",
          text: error?.message || "An error occurred while updating the item",
        });
      }
    },
  });

  useEffect(() => {
      dispatch(getStation());
    // Update the form values when selectedMetal changes
    formik.setValues({
      name: selectedMetal?.name || "",
      description: selectedMetal?.description || "",
      station: selectedMetal?.station_code || "",
    });
  }, [selectedMetal]);

  const handleClose = () => {
    formik.setValues({
      name: selectedMetal?.name || "",
      description: selectedMetal?.description || "",
      station: selectedMetal?.station_code || "",
    });
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label={
                <span>
                  Metal Name
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
              }
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel htmlFor="station" style={{ marginLeft: "-14px" }}>
                Select Station
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                name="station"
                id="station"
                value={formik.values.station}
                onChange={(e) => {
                  const selectedStationCode = e.target.value;
                  const selectedStationObj =
                    station.find((item) => item.code === selectedStationCode) || {};
            
                  // Set both the station code and the full object
                  formik.setFieldValue("station", selectedStationCode);
                  formik.setFieldValue("stationObj", selectedStationObj);
                }}
                error={formik.touched.station && Boolean(formik.errors.station)}
              >
                <option value=""></option>
                {station.map((option, index) => (
                  <option key={option.id} value={option.code}>
                    {option.name} ({option.code})
                  </option>
                ))}
              </Select>
              {formik.touched.station && formik.errors.station && (
                <FormHelperText error style={{ fontWeight: 400 }}>
                  {formik.errors.station}
                </FormHelperText>
              )}
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Metal Description"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton size="small" color="success" onClick={handleClose}>
            Cancel
          </MDButton>
          <MDButton size="small" color="info" type="submit" disabled={loading}>
            {loading ? <MoonLoader color="black" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Metaledit;
