
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create role

export const createMetal = createAsyncThunk("createMetal", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.post(
      `metal-master/api/MetalMaster/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMetal = createAsyncThunk("getMetal", async (data, {rejectWithValue}) => {
    try {
      if (data) {
        const response = await axios.get(
          `metal-master/api/MetalMaster/v1/get?station_code=${data}`
        );
        return await response.data;
      } else {
        const response = await axios.get(
          `metal-master/api/MetalMaster/v1/get`
        );
        return await response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateMetal= createAsyncThunk("updateMetal", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.put(
      `metal-master/api/MetalMaster/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteMetal = createAsyncThunk("deleteMetal", async (data, {rejectWithValue}) => {
 

  try {
    const response = await axios.put(
      `metal-master/api/MetalMaster/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




