import {
  combineReducers,
  legacy_createStore as createStore,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./Reducers/AuthReducer";
import { composeWithDevTools } from "redux-devtools-extension";

import role from "app/Role Master/reducer/roleDetailSlice";
import department from "app/departmentMaster/reducer/departmentDetailSlice";
import machine from "app/MachineMaster/reducer/machineDetailSlice";
import designation from "app/designationMaster/reducer/designationDetailSlice";
import station from "app/stationMaster/reducer/stationDetailSlice";
import category from "app/stationMaster/reducer/categoryDetailSlice";
import checklist from "app/stationMaster/reducer/checklistDetailSlice";
import user from "app/UserMaster/reducer/userDetailSlice";
import stationassociation from "app/stationMaster/reducer/stationassociationDetailSlice";
import attribute from "app/attributeMaster/reducer/attributeDetailSlice";
import filteredattribute from "app/attributeMaster/reducer/attributeDetailSlice";

import fileupload from "app/stationMaster/reducer/fileUploadDetailSlice";
import typemaster from "app/bomMaster/reducer/typemasterDetailSlice";
import itemmaster from "app/itemMaster/reducer/itemmasterDetailSlice";
import processmaster from "app/bomMaster/reducer/processmasterDetailSlice";
import bomcreationmaster from "app/bomMaster/reducer/bomcreationmasterDetailSlice";
import headerchecklist from "app/ChecklistOperator/reducer/headercheclklistDetailSlice";
import codeDetailReducer from "app/ChecklistOperator/reducer/checklistServiceDetailslice";
import operatorCode from "app/Codereducer/codeParamSlice";
import checklistservice from "app/ChecklistOperator/reducer/checklistServiceDetailslice";

import productdetail from "app/productDetail/reducer/productDetailSlice";
import bomstation from "app/step/reducer/bomstationDetailSlice";

import filteredMessages from "app/webSocketUI/reducer/filteredMessagesSlice";

import partprocessed from "app/analytics/reducer/partprocessedDetailSlice";
import attributevariation from "app/analytics/reducer/attributevariationDetailSlice";
import attributevariationpart from "app/analytics/reducer/attributebypartDetailSlice";
import noofpart from "app/analytics/reducer/numberofpartDetailSlice";
import graph1Messages from "app/analytics/reducer/graph1MessagesSlice";
import graph3Messages from "app/analytics/reducer/graph3MessagesSlice";
import finalstation from "app/analytics/reducer/finalStationDetailSlice";
import partname from "app/webSocketUI/reducer/partNameDetailSlice";
import configuration from "app/Configuration Master/reducer/configurationDetailSlice";
import attributeList from "../productlist/reducer/attributelistDetailSlice";
import  machinechecklist from "app/stationMaster/reducer/machinechecklistDetailSlice";
import  pokayoke from "app/stationMaster/reducer/pokayokeDetailSlice";
import  shift from "app/shiftMaster/reducer/shiftDetailSlice";
import getReportServiceData from "./slices/getReportServiceData";
import getSPCServiceData from "./slices/getSPCServiceData";
import getProductionMessageReducer from './slices/getProductionMessage';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import metal from "app/MetalMaster/reducer/metalDetailSlice";
import riseticket from "app/RaiseTicket/reducer/riseticketDetailSlice";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'AuthReducer',
    'attributeList',
    'machinechecklist',
    'pokayoke',
    'shift',
    'getReportServiceData',
  ]
};

const rootReducer = combineReducers({
    AuthReducer,
    role,
    department,
    machine,
    designation,
    station,
    category,
    checklist,
    user,
    stationassociation,
    attribute,
    filteredattribute,
    fileupload,
    typemaster,
    itemmaster,
    processmaster,
    bomcreationmaster,
    headerchecklist,
    checklistservice,
    productdetail,
    bomstation,
    operatorCode,
    filteredMessages,
    partprocessed,
    attributevariation,
    attributevariationpart,
    noofpart,
    graph1Messages,
    finalstation,
    graph3Messages,
    partname,
    configuration,
    attributeList,
    machinechecklist,
    pokayoke,
    shift,
    metal,
    riseticket,
    getReportServiceData,
    productionMessage: getProductionMessageReducer,
    getSPCServiceData,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };






















// const store = createStore(
//   combineReducers({
//     AuthReducer,

//     role,
//     department,
//     machine,
//     designation,
//     station,
//     category,
//     checklist,
//     user,
//     stationassociation,
//     attribute,
//     fileupload,
//     typemaster,
//     itemmaster,
//     processmaster,
//     bomcreationmaster,
//     headerchecklist,
//     checklistservice,
//     productdetail,
//     bomstation,
//     // codeDetail: codeDetailReducer,
//     operatorCode,


//     filteredMessages,

//     partprocessed,
//     attributevariation,
//     attributevariationpart,
//     noofpart,
//     graph1Messages,
//     finalstation,
//     graph3Messages,
//     partname,
//     configuration,
//     attributeList,
//     machinechecklist,
//     pokayoke,
//     shift,
//     getReportServiceData,

//     // code: codeReducer, // Add the codeReducer here
//   }),
//   composeWithDevTools(applyMiddleware(thunk))
// );

// export default store;
