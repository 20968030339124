import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { Grid, Select } from "@mui/material";
//edit icon
import { FaRegEdit } from "react-icons/fa";

import RaiseTicketedit from "./RaiseTicketedit";

import MDButton from "components/MDButton";
import CreateTicketPopup from "./CreateTicketPopup";
import { Autocomplete, TextField } from "@mui/material";
import {
  deleteRiseTicket,
  getRiseTicket,
  updateRiseTicket,
} from "../service/RaiseTicket";

function RaiseTicket() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { riseticket, loading, error } = useSelector(
    (state) => state.riseticket
  );
  // const [loading, setLoading] = useState(true);

  const [openpopup, setopenpopup] = useState(false);
  const [openpopupTicket, setopenpopupTicket] = useState(false);

  const [selectedTicket, setselectedTicket] = useState(null);
  const [ticket, setTicket] = useState({ id: 1, name: "Man Power", value: "MANPOWER" });
  const [recstatus, setRecstatus] = useState(null);
  const [ticketStatusMap, setTicketStatusMap] = useState(new Map());
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const defaultpropsStatus = {
    options: [
      { id: 1, name: "OPEN", value: "OPEN" },
      { id: 0, name: "CLOSED", value: "CLOSED" },
    ],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const tickettype = {
    options: [
      // { id: 6, name: "ALL", value: "ALL" },
      { id: 1, name: "Man Power", value: "MANPOWER" },
      { id: 2, name: "Machine Breakdown", value: "MACHINEBREAKDOWN" },
      { id: 3, name: "METALNOTAVAILABLE", value: "MetalNotAvailable" },
    ],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

 
  async function handleStatusChange(rowData, newStatus) {
    let formattedDate =
      new Date().toISOString().split("T")[0].replace(/-/g, "/") +
      ` ${new Date().getHours() % 12 || 12}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, "0")}${new Date().getHours() >= 12 ? "PM" : "AM"}`;

    const updatedMetal = {
      ...rowData,
      recstatus: newStatus,
      updateddate: formattedDate,
    };

    const response = await dispatch(updateRiseTicket(updatedMetal));

    if (!response.error && !loading) {
      swal("Good job!", "Status has been successfully updated.", "success");
      const data = {
        recstatus: recstatus?.value,
        losetype: ticket?.value,
      };
      dispatch(getRiseTicket(data));
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: error?.message || "An error occurred while updating the item",
      });
    }
   


  }

  const commonHeader = [
    {
      Header: "Created Date",
      accessor: "createddate",
      Cell: ({ value }) => {
        const formattedDate = new Date(value).toDateString();
        return formattedDate;
      },
    },
    {
      Header: "Modified By",
      accessor: "modifieddate",
      Cell: ({ value }) => {
        const formattedDate = new Date(value).toDateString();
        return formattedDate;
      },
    },
    {
      Header: "Status",
      accessor: "changestatus",
      Cell: ({ row }) => {
        const ticketId = row.original.id;
        const recstatus = row.original.recstatus;

        return recstatus === "OPEN" ? (
          <div>
            <Select
              native
              variant="outlined"
              value={ticketStatusMap.get(ticketId) || "OPEN"}
              onChange={(e) => {
                const newStatus = e.target.value;

                setTicketStatusMap((prev) => {
                  const updatedMap = new Map(prev);
                  updatedMap.set(ticketId, newStatus);
                  return updatedMap;
                });
                handleStatusChange(row.original, newStatus);
              }}
            >
              <option value="OPEN">OPEN</option>
              <option value="CLOSED">CLOSED</option>
            </Select>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ color: "green", fontWeight: "bold" }}>{recstatus}</span>
          </div>
        );
      },
    },

  ]
  const Manpower = [
    { Header: "Lose Type", accessor: "lose_type" },
    { Header: "Man Power", accessor: "value.manpower" },
    ...commonHeader
  
  ];
  const allHeader = [
    { Header: "Lose Type", accessor: "lose_type" },
    { Header: "Station/metal name", accessor: "value.name" },
    { Header: "Man Power", accessor: "value.manpower" },
    ...commonHeader

  ]

  const MACHINEBREAKDOWN = [
    { Header: "Lose Type", accessor: "lose_type" },
    { Header: "Station", accessor: "value.name" },
    { Header: "Stationcode", accessor: "value.code" },
    ...commonHeader
  ];

  const MetalNotAvailable = [
    { Header: "Lose Type", accessor: "lose_type" },
    { Header: "Metal", accessor: "value.name" },
    { Header: "Stationname", accessor: "value.station_name" },
    { Header: "Stationcode", accessor: "value.station_code" },
    ...commonHeader
  ];
  // const conditonalColumns =
  // ticket?.value == "MANPOWER"
  //   ? Manpower
  //   : ticket?.value =="MACHINEBREAKDOWN"
  //   ? MACHINEBREAKDOWN
  //   : MetalNotAvailable;

    let conditionalColumns;

    switch (ticket?.value) {
      case "MANPOWER":
        conditionalColumns = Manpower;
        break;
      case "MACHINEBREAKDOWN":
        conditionalColumns = MACHINEBREAKDOWN;
        break;
        case "MetalNotAvailable":
        conditionalColumns = MetalNotAvailable;
        break;
      default:
        conditionalColumns = allHeader;
    }
    const successfullycreate=(data2, recstatus2)=>{
    
      setTicket(data2)
      setRecstatus(recstatus2)
      const data = {
        recstatus: recstatus2!==null?recstatus2?.value: undefined,
        losetype: data2?.value,
      };
      dispatch(getRiseTicket(data));

    }
    

  useEffect(() => {
    const data = {
      recstatus: undefined,
      losetype: ticket?.value,
    };
    dispatch(getRiseTicket(data));
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Rise Ticket List
                  </MDTypography>
                </span>

                <span
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                >
                  <MDButton
                    color="success"
                    onClick={() => setopenpopupTicket(true)}
                  >
                    ADD
                  </MDButton>
                </span>
              </div>
<Grid style={{ display: "flex", flexDirection: "row",flexWrap:'wrap' }}>
            
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                    
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        sx={{ width: 200 }}
                        {...tickettype}
                        value={ticket}
                        onChange={(event, newValue) => {
                          setTicket(newValue);
                          if (newValue) {
                            const data = {
                              recstatus: recstatus?.value,
                              losetype: newValue?.value,
                            };
                            // if(newValue?.value==='ALL'){
                            //   return dispatch(getRiseTicket());
                            // }
                            dispatch(getRiseTicket(data));
                          } else {
                            if (recstatus) {
                              const data = {
                                recstatus: recstatus?.value,
                                losetype: newValue?.value,
                              };
                              dispatch(getRiseTicket(data));
                            } else {
                              dispatch(getRiseTicket());
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Ticket" />
                        )}
                      />
                    </span>
                  </div>
                </MDBox>

                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        sx={{ width: 180 }}
                        {...defaultpropsStatus}
                        value={recstatus}
                        onChange={(event, newValue) => {
                        
                          setRecstatus(newValue);
                          if (newValue !==null) {
                            const data = {
                              losetype: ticket?.value,
                              recstatus: newValue?.value,
                            };
                            dispatch(getRiseTicket(data));
                          } else {
                            if (ticket) {
                              const data = {
                                recstatus: newValue?.value,
                                losetype: ticket?.value,
                              };
                              dispatch(getRiseTicket(data));
                            } else {
                              dispatch(getRiseTicket());
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Status" />
                        )}
                      />
                    </span>
                  </div>
                </MDBox>
         
              </Grid>
            </MDBox>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <DataTable
             
                canSearch={true}
                table={{
                  columns:conditionalColumns,
                  rows: riseticket || [],
                }}
              />
            )}
          </Card>
        </MDBox>
      </MDBox>
      {openpopup && (
        <RaiseTicketedit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Raise Ticket Edit"}
          selectedTicket={selectedTicket}
          // selectedTicket={{
          //   losetype: "METALNOTAVAILABLE",
          //   manpower: "5",
          //   metal: "Aluminum",
          //   station_code: "STNIRHUB",
          // }}
        />
      )}

      {openpopupTicket && (
        <CreateTicketPopup
          openpopupTicket={openpopupTicket}
          setopenpopupTicket={setopenpopupTicket}
          successfullycreate={successfullycreate}
          ticket2={ticket}
          recstatus2={recstatus}
        />
      )}
    </DashboardLayout>
  );
}

export default RaiseTicket;
