/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "role-form",
  formField: {
    manPower: {
      name: "manPower",
      label: "Enter no of manpower short",
      type: "number",
      errorMsg: "Man power is required.",
    },
    losetype: {
      name: "losetype",
      label: "Lose Type",
      type: "text",
      errorMsg: "Lose Type is required.",
    },
    station: {
      name: "station",
      label: "Station",
      type: "text",
      errorMsg: "Station is required.",
    },
    metal: {
      name: "metal",
      label: "Metal",
      type: "text",
      errorMsg: "Metal is required.",
    },
  },
};

export default form;
