import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{metalName,station},
} = checkout;

const validations = [
  Yup.object().shape({
    [metalName.name]: Yup.string().required(metalName.errorMsg),
    [station.name]: Yup.string().required(station.errorMsg),
  }),
];

export default validations;
